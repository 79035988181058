<template>
  <div class="directflightsonly_top d-flex align-items-center">
    <div class="btn-group">
        <button v-b-modal.filtermodal1 class="btn btnoutline dropdown-toggle" type="button">
        {{selectedDirection ? $t(`sabre.search-panel.${selectedDirection}`) : $t('sabre.search-panel.directions')}}
        </button>
    </div>

    <b-modal
      id="filtermodal1"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="adjustbtnModal"
    >
      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="close()"></button>
        <div class="filtter_head">
          <h3>{{$t("sabre.search-panel.directions")}}</h3>
        </div>
      </template>
      <template #default="{ close }">
        <select-direction type="modal" propsClassName="text-right mx-3 mb-3 filttermobailboby"/>

        <div class="adjust_fillterbox_footer">
          <div class="btn-group-area d-flex justify-content-center">
            <button class="save_btn" @click="() => close()">{{$t("sabre.buttons.save")}}</button>
          </div>
        </div>
      </template>
    </b-modal>

    <div class="btn-group">
      <button v-b-modal.filtermodal2 class="btn btnoutline dropdown-toggle" type="button">
      {{sldDepartmentList.length > 0 ? $t(`sabre.search-panel.${sldDepartmentList[0]}`) : $t("sabre.search-panel.department")}}
      </button>
    </div>

    <b-modal
      id="filtermodal2"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="adjustbtnModal"
    >

      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="close()"></button>
        <div class="filtter_head">
          <h3>{{$t("sabre.search-panel.department")}}</h3>
        </div>
      </template>

      <template #default="{close}">
        <select-department propsClassName="text-right filttermobailboby" type="modal"/>

        <div class="adjust_fillterbox_footer">
          <div class="btn-group-area d-flex justify-content-center">
            <button class="save_btn" @click="() => close()">{{$t("sabre.buttons.save")}}</button>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BModal } from 'bootstrap-vue';

export default {
  name: 'search-mobile-up-pane',
  components: {
    BModal,
    selectDirection: () => import('../searchPanelAtom/direction/selectDirection'),
    selectDepartment: () => import('../searchPanelAtom/department/selectDepartment'),
  },
  computed: {
    ...mapGetters({
      sldDepartmentList: 'GET_SABRE_SELECTED_FLIGHT_DEPARTMENTS',
      departments: 'GET_SABRE_FLIGHT_DEPARTMENT_LIST',
      selectedDirection: 'GET_SABRE_SELECTED_FLIGHT_DIRECTION',
      directionList: 'GET_SABRE_FLIGHT_DIRECTION_LIST',
    }),
  },
  mounted() {
    this.$store.commit('SET_SABRE_FLIGHT_DEPARTMENT', { item: this.departments[0] });
    this.$store.commit('SET_SABRE_SELECTED_FLIGHT_DIRECTION', this.directionList[0]);
  },
};
</script>
